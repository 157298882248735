@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (prefers-color-scheme: dark) {
  html,
  body,
  #root {
    min-height: 100%;
    height: 100vh;
    background: #0f0e0e;
    color: #eeeeee;
  }
}

@media screen and (prefers-color-scheme: light) {
  html,
  body,
  #root {
    background: white;
    color: #0f0e0e;
  }
}
